import axios from "axios";
import env from "config";

const BASE_URL = `${env.API_URL}/v1`;

export default async function Api(path, method, option) {
  const url = BASE_URL + path;

  return axios[method](url, option?.payload)
    .then((response) => {
      return { response: response.data };
    })
    .catch((error) => {
      return { error: error.response.data };
    });
}

import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import OrganisationOnboardLayout from 'layouts/authentication/register/organisation/onboard'

// ORGANISATIONAL
const Organization = ComponentLoader(
  lazy(() =>
    import('../layouts/authentication/register/organisation/organization')
  )
)

const Design = ComponentLoader(
  lazy(() => import('../layouts/authentication/register/organisation/design'))
)

const Steps = [
  <Organization />,
  <Design description="Create your organisation's default card design. You can update this at  any time." />,
]

const OnboardOrganisationRoutes = (step) => [
  {
    path: 'register',
    element: <OrganisationOnboardLayout />,
    children: [
      {
        path: '',
        index: true,
        element: Steps[step - 1],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={'register'} />,
  },
]

export default OnboardOrganisationRoutes

import React, { useState, useEffect, createContext, useContext } from "react";
import Api from "utils/api";
import { useAuth } from "./auth";

const initDesignContextPropsState = {
  loading: false,
  design: [],
  setDesign: () => {},
};

const DesignContext = createContext(initDesignContextPropsState);

const useDesign = () => {
  return useContext(DesignContext);
};

const DesignProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [design, setDesign] = useState([]);

  const getDesign = async () => {
    const { response } = await Api(
      "/design/selected_design?selectedDesign=" +
        currentUser.account?.selectedDesign,
      "get"
    );
    setLoading(false);

    if (response) setDesign(response.data?.design);
  };

  const onLoad = () => {
    getDesign();
  };

  useEffect(onLoad, []);

  return (
    <DesignContext.Provider
      value={{
        loading,
        design,
        setDesign,
      }}
    >
      {children}
    </DesignContext.Provider>
  );
};

export { DesignProvider, useDesign };

import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import ComponentLoader from "./component-loader";
import PerosnalAccountLayout from "layouts/personal-account/component/perosnal-account-layout";
import { ROLE } from "enums/user";

// MAIN
const Admin = ComponentLoader(
  lazy(() => import("../layouts/super-admin/admin"))
);

// ACCOUNT
const Account = ComponentLoader(
  lazy(() => import("../layouts/common/account/account"))
);
const Profile = ComponentLoader(
  lazy(() => import("../layouts/common/account/profile"))
);
const UpdatePassword = ComponentLoader(
  lazy(() => import("../layouts/common/account/update-pass"))
);

// SUPER-ADMIN CARD-HOLDER
const CardHolders = ComponentLoader(
  lazy(() => import("../layouts/admin/cardholder"))
);
const Vouchers = ComponentLoader(
  lazy(() => import("../layouts/admin/cardholder/voucher"))
);

const Orders = ComponentLoader(
  lazy(() => import("../layouts/admin/cardholder/orders"))
);

const DeletedAdminCardHolders = ComponentLoader(
  lazy(() => import("../layouts/admin/cardholder/deleted-admin-cardholder"))
);
const Create = ComponentLoader(
  lazy(() => import("../layouts/super-admin/cardholders/create"))
);
const CardHoldersList = ComponentLoader(
  lazy(() => import("../layouts/super-admin/cardholders/cardholder-list"))
);

const VoucherList = ComponentLoader(
  lazy(() => import("../layouts/super-admin/cardholders/voucher-list"))
);

const OrdersList = ComponentLoader(
  lazy(() => import("../layouts/super-admin/cardholders/orders-list"))
);

const DeletedAdminCardHoldersList = ComponentLoader(
  lazy(() =>
    import("../layouts/super-admin/cardholders/deleted-cardholder-list")
  )
);
// const Bulkmanage = ComponentLoader(
//   lazy(() => import('../layouts/admin/cardholder/bulkmanage'))
// )

// ENTERPRISE
const Enterprise = ComponentLoader(
  lazy(() => import("../layouts/super-admin/enterprise"))
);
const EnterpriseAccountList = ComponentLoader(
  lazy(() => import("../layouts/super-admin/enterprise/enterpriseaccount"))
);

// CUSTOMERS
const Customers = ComponentLoader(
  lazy(() => import("../layouts/super-admin/customers/customeraccount"))
);

const AccountCardholders = ComponentLoader(
  lazy(() => import("../layouts/super-admin/customers/cardholders"))
);

const AccountCardholdersList = ComponentLoader(
  lazy(() =>
    import("../layouts/super-admin/customers/cardholders/cardholder-list")
  )
);

const SuperAdminRoutes = [
  {
    path: "",
    element: <PerosnalAccountLayout />,
    children: [
      {
        path: "",
        index: true,
        element: <Admin />,
      },
      {
        path: "account",
        element: <Account />,
        children: [
          {
            path: "",
            index: true,
            element: <Profile />,
          },
          {
            path: "password",
            element: <UpdatePassword />,
          },
        ],
      },
      {
        path: "cardholders",
        element: <CardHolders role={ROLE.SUPER_ADMIN} />,
        children: [
          {
            path: "",
            index: true,
            element: <CardHoldersList />,
          },
          {
            path: "create",
            element: <Create />,
          },
          {
            path: "update",
            element: <Create />,
          },
        ],
      },

      {
        path: "vouchers",
        element: <Vouchers role={ROLE.SUPER_ADMIN} />,
        children: [
          {
            path: "",
            index: true,
            element: <VoucherList />,
          },
        ],
      },
      {
        path: "orders",
        element: <Orders role={ROLE.SUPER_ADMIN} />,
        children: [
          {
            path: "",
            index: true,
            element: <OrdersList />,
          },
        ],
      },
      {
        path: "deletedadmincardholders",
        element: <DeletedAdminCardHolders role={ROLE.SUPER_ADMIN} />,
        children: [
          {
            path: "",
            index: true,
            element: <DeletedAdminCardHoldersList />,
          },
          {
            path: "create",
            element: <Create />,
          },
          {
            path: "update",
            element: <Create />,
          },
          // {
          //   path: 'bulkmanage',
          //   element: <Bulkmanage />,
          // },
        ],
      },
      {
        path: "enterprise",
        element: <Enterprise />,
        children: [
          {
            path: "",
            element: <EnterpriseAccountList />,
          },
        ],
      },
      {
        path: "customers",
        children: [
          {
            path: "",
            element: <Customers />,
          },
          {
            path: "cardholders",
            element: <AccountCardholders />,
            children: [
              {
                path: "",
                index: true,
                element: <AccountCardholdersList />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to={""} />,
      },
    ],
  },

  {
    path: "*",
    element: <Navigate to={""} />,
  },
];

export default SuperAdminRoutes;

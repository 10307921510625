import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import EmailVerificationLayout from 'layouts/authentication/email-verification/email-verification'

const VerifyEmail = ComponentLoader(
  lazy(() =>
    import('../layouts/authentication/email-verification/verification')
  )
)

const AuthenticateEmail = ComponentLoader(
  lazy(() =>
    import('../layouts/authentication/email-verification/authenticateemail')
  )
)

const EmailVerificationRoutes = (emailSent) => [
  {
    path: 'verify-email',
    element: <EmailVerificationLayout />,
    children: [
      {
        path: '',
        element: emailSent ? <VerifyEmail /> : <AuthenticateEmail />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={'verify-email'} />,
  },
]

export default EmailVerificationRoutes

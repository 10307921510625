import React from 'react'
import { useAuth } from 'context/auth'
import AuthRoutes from './auth-routes'
import AccountRegisterationRoutes from './account-registration-routes'
import OnboardOrganisationRoutes from './onboard-organisation-routes'
import OnboardPersonalAccountRoutes from './onboard-personal-routes'
import OnboardEnterpriseRoutes from './onboard-enterprise-routes'
import OrganisationRoutes from './organisation-routes'
import PersonalAccountRoutes from './personal-account-routes'
import EnterpriseRoutes from './enterprise-routes'
import EmailVerificationRoutes from './verification-routes'
import PublicRoutes from './public-routes'
import EmployeeRoutes from './employee-routes'
import { ACCOUNT_TYPE, ROLE } from 'enums/user'
import SuperAdminRoutes from './super-admin-route'

const Routes = () => {
  const { currentUser } = useAuth()
  const routes = PublicRoutes

  // PUBLIC
  if (!currentUser) return routes.concat(AuthRoutes)

  // SUPER ADMIN
  if (currentUser.role == ROLE.SUPER_ADMIN)
    return routes.concat(SuperAdminRoutes)

  // REGISTER ACCOUNT
  if (!currentUser.account) return routes.concat(AccountRegisterationRoutes)

  // ONBOARD ORGANISATION
  if (
    currentUser.account.type === ACCOUNT_TYPE.ORGANISATION &&
    currentUser.onBoardingStep < 4
  )
    return routes.concat(OnboardOrganisationRoutes(currentUser.onBoardingStep))

  // ONBOARD PERSONAL ACCOUNT
  if (
    currentUser.account.type === ACCOUNT_TYPE.PERSONAL &&
    currentUser.onBoardingStep < 3
  )
    return routes.concat(
      OnboardPersonalAccountRoutes(currentUser.onBoardingStep)
    )

  // ONBOARD ENTERPRISE
  if (
    currentUser.account.type === ACCOUNT_TYPE.ENTERPRISE &&
    currentUser.onBoardingStep < 3
  )
    return routes.concat(OnboardEnterpriseRoutes(currentUser.onBoardingStep))

  // EMAIL VERIFICATION
  // if (!currentUser.verified)
  // return EmailVerificationRoutes(currentUser.verificationEmailSent)

  // ORGANISATION ACCOUNT
  if (
    currentUser.account.type === ACCOUNT_TYPE.ORGANISATION &&
    currentUser.role === ROLE.ADMIN
  )
    return routes.concat(OrganisationRoutes)

  // PERSONAL ACCOUNT
  if (currentUser.account.type === ACCOUNT_TYPE.PERSONAL)
    return routes.concat(PersonalAccountRoutes)

  // ENTERPRISE ACCOUNT
  if (
    currentUser.account.type === ACCOUNT_TYPE.ENTERPRISE &&
    currentUser.role === ROLE.ADMIN
  )
    return routes.concat(EnterpriseRoutes)

  return routes.concat(EmployeeRoutes)
}

export default Routes

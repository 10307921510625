import React, { useEffect, useState } from "react";
import images from "assets/images/Guidelines_page-0001.jpg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Api from "utils/api";
import { AUTH_LOCAL_STORAGE_KEY } from "utils/auth-helper";

function Useronboard() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const lsValue = JSON.stringify({ token });
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  const pathname = window.location.pathname.split("/");
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);

  // const verifyToken = async () => {
  //   const { response } = await Api("/auth/verify_token", "get");
  //   setUser(response.data);
  // };

  const verifyToken = async () => {
    try {
      const { response } = await Api("/auth/verify_token", "get");
      if (response && response.data) {
        setUser(response.data);
        setError(false);
      } else {
        throw new Error("Invalid response");
      }
    } catch (error) {
      setError(true);
      console.error("Token verification failed:", error);
    }
  };

  const onLoad = () => {
    verifyToken();
  };

  useEffect(onLoad, []);

  useEffect(() => {
    if (error) {
      navigate("/error");
    }
  }, [error, navigate]);

  return (
    <div className="tw-grid md:tw-h-screen tw-items-stretch md:tw-grid-cols-2 md:tw-p-0 tw-p-5">
      <div className="md:tw-flex tw-items-center tw-justify-center d:tw-h-screen tw-hidden tw-bg-[rgb(44,48,83)]">
        <div className="tw-fixed tw-top-20 tw-left-20">
          {pathname.length > 2 && (
            <div onClick={() => navigate(-1)} className="tw-block tw-mb-4">
              <span className="tw-cursor-pointer" style={{ color: "white" }}>
                ← back
              </span>
            </div>
          )}
        </div>
        <div className="tw-block tw-w-96">
          <img src={images} alt="" />
        </div>
      </div>

      <div class="tw-flex md:tw-items-center md:tw-justify-center md:tw-px-4 tw-py-8">
        <div class="md:tw-max-w-sm tw-w-full">
          <h1 class="tw-font-heading  md:tw-text-4xl tw-font-semibold tw-mb-2 tw-text-4xl">
            Hi {user?.fullName}
            <span class="tw-text-electricGreen">,</span>
          </h1>
          <strong class="tw-block tw-mb-4 tw-text-xl">
            Create a password to start your session
          </strong>

          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Useronboard;

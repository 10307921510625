import React from 'react'

function SubscriptionIcon() {
  return (
    <div>
      {' '}
      <svg
        className='tw-CircularProgressbar '
        viewBox='0 0 100 100'
        data-test-id='CircularProgressbar'
      >
        <path
          className='tw-CircularProgressbar-trail'
          d='
M 50,50
m 0,-46
a 46,46 0 1 1 0,92
a 46,46 0 1 1 0,-92
'
          stroke-width='8'
          fill-opacity='0'
          style={{
            stroke: 'rgb(214, 214, 214)',
            strokeLinecap: 'butt',
            strokeDasharray: '289.027px, 289.027px',
            strokeDashoffset: '0px',
          }}
        ></path>
        <path
          className='tw-CircularProgressbar-path'
          d='
M 50,50
m 0,-46
a 46,46 0 1 1 0,92
a 46,46 0 1 1 0,-92
'
          stroke-width='8'
          fill-opacity='0'
          style={{
            stroke: 'rgb(28, 215, 97)',
            strokeLinecap: 'butt',
            transitionDuration: '0.5s',
            strokeDasharray: '289.027px, 289.027px',
            strokeDashoffset: '0px',
          }}
        ></path>
        <text
          className='tw-CircularProgressbar-text'
          x='50'
          y='50'
          style={{
            fill: ' rgb(28, 215, 97)',
            fontSize: '16px',
            textAnchor: 'middle',
            dominantBaseline: 'middle',
          }}
        >
          3 of 3
        </text>
      </svg>
    </div>
  )
}

export default SubscriptionIcon

import React from 'react'

function OragnizationIcon() {
  return (
    <svg
      className='tw-CircularProgressbar '
      viewBox='0 0 100 100'
      data-test-id='CircularProgressbar'
    >
      <path
        className='tw-CircularProgressbar-trail'
        d='
                    M 50,50
                    m 0,-46
                    a 46,46 0 1 1 0,92
                    a 46,46 0 1 1 0,-92
    '
        stroke-width='8'
        fill-opacity='0'
        style={{
          stroke: 'rgb(214, 214, 214)',
          strokeLinecap: 'butt',
          strokeDasharray: '289.027px, 289.027px',
          strokeDashoffset: '0px',
        }}
      ></path>
      <path
        className='tw-CircularProgressbar-path'
        d='
                M 50,50
                m 0,-46
                a 46,46 0 1 1 0,92
                a 46,46 0 1 1 0,-92 
                '
        stroke-width='8'
        fill-opacity='0'
        style={{
          stroke: 'rgb(28, 215, 97)',
          strokelLinecap: 'butt',
          transitionDuration: '0.5s',
          strokeDasharray: '289.027px, 289.027px',
          strokeDashoffset: '192.684px',
        }}
      ></path>
      <text
        className='tw-CircularProgressbar-text'
        style={{
          textAnchor: 'middle',
          dominantBaseline: 'middle',
          fill: 'rgb(28, 215, 97)',
          fontSize: '16px',
        }}
        x='50'
        y='50'
      >
        1 of 3
      </text>
    </svg>
  )
}

export default OragnizationIcon

import React from 'react'

function DesignIcon() {
  return (
    <svg
      class='tw-CircularProgressbar '
      viewBox='0 0 100 100'
      data-test-id='CircularProgressbar'
    >
      <path
        class='tw-CircularProgressbar-trail'
        d='
M 50,50
m 0,-46
a 46,46 0 1 1 0,92
a 46,46 0 1 1 0,-92
'
        stroke-width='8'
        fill-opacity='0'
        style={{
          stroke: 'rgb(214, 214, 214)',
          strokeLinecap: 'butt',
          strokeDasharray: '289.027px, 289.027px',
          strokeDashoffset: '0px',
        }}
      ></path>
      <path
        class='CircularProgressbar-path'
        d='
M 50,50
m 0,-46
a 46,46 0 1 1 0,92
a 46,46 0 1 1 0,-92
'
        stroke-width='8'
        fill-opacity='0'
        style={{
          stroke: 'rgb(28, 215, 97)',
          strokeLinecap: 'butt',
          transitionDuration: '0.5s',
          strokeDasharray: '289.027px, 289.027px',
          strokeDashoffset: '144.513px',
        }}
      ></path>
      <text
        class='CircularProgressbar-text'
        x='50'
        y='50'
        style={{
          textAnchor: 'middle',
          dominantBaseline: 'middle',
          fill: 'rgb(28, 215, 97)',
          fontSize: '16px',
        }}
      >
        2 of 3
      </text>
    </svg>
  )
}

export default DesignIcon

import React from "react";
import { useIntl } from "react-intl";
import footerImage from "../assets/svg/footerimg.svg";
import "./footer.css";

const Footer = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="main-row">
          <img src={footerImage} alt="cardly-logo" />

          <div className="divider" />
          <>
            <div>
              <div class="icon-row">
                <a
                  href="mailto:support@cardlysa.com"
                  class="text-white p-2 lg:p-4 text-xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                  >
                    <g id="mi:email">
                      <path
                        id="Vector"
                        d="M2.8028 7.99967C2.8028 7.29243 3.08375 6.61415 3.58385 6.11406C4.08395 5.61396 4.76223 5.33301 5.46947 5.33301H26.8028C27.51 5.33301 28.1883 5.61396 28.6884 6.11406C29.1885 6.61415 29.4695 7.29243 29.4695 7.99967V23.9997C29.4695 24.7069 29.1885 25.3852 28.6884 25.8853C28.1883 26.3854 27.51 26.6663 26.8028 26.6663H5.46947C4.76223 26.6663 4.08395 26.3854 3.58385 25.8853C3.08375 25.3852 2.8028 24.7069 2.8028 23.9997V7.99967ZM7.4948 7.99967L16.1361 15.561L24.7775 7.99967H7.4948ZM26.8028 9.77167L17.0148 18.337C16.7717 18.55 16.4594 18.6675 16.1361 18.6675C15.8129 18.6675 15.5006 18.55 15.2575 18.337L5.46947 9.77167V23.9997H26.8028V9.77167Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </a>
                <a
                  href="https://wa.me/+966501223171"
                  target="_blank"
                  class="text-white p-2 lg:p-4 text-xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                  >
                    <path
                      d="M25.5361 6.54707C24.3137 5.31231 22.8577 4.3333 21.253 3.66714C19.6483 3.00098 17.927 2.66102 16.1895 2.66707C8.90948 2.66707 2.97614 8.6004 2.97614 15.8804C2.97614 18.2137 3.58948 20.4804 4.73614 22.4804L2.86948 29.3337L9.86948 27.4937C11.8028 28.5471 13.9761 29.1071 16.1895 29.1071C23.4695 29.1071 29.4028 23.1737 29.4028 15.8937C29.4028 12.3604 28.0295 9.0404 25.5361 6.54707ZM16.1895 26.8671C14.2161 26.8671 12.2828 26.3337 10.5895 25.3337L10.1895 25.0937L6.02948 26.1871L7.13614 22.1337L6.86948 21.7204C5.77314 19.9697 5.191 17.9461 5.18948 15.8804C5.18948 9.82707 10.1228 4.89374 16.1761 4.89374C19.1095 4.89374 21.8695 6.04041 23.9361 8.12041C24.9595 9.13902 25.7704 10.3506 26.322 11.685C26.8736 13.0193 27.1549 14.4499 27.1495 15.8937C27.1761 21.9471 22.2428 26.8671 16.1895 26.8671ZM22.2161 18.6537C21.8828 18.4937 20.2561 17.6937 19.9628 17.5737C19.6561 17.4671 19.4428 17.4137 19.2161 17.7337C18.9895 18.0671 18.3628 18.8137 18.1761 19.0271C17.9895 19.2537 17.7895 19.2804 17.4561 19.1071C17.1228 18.9471 16.0561 18.5871 14.8028 17.4671C13.8161 16.5871 13.1628 15.5071 12.9628 15.1737C12.7761 14.8404 12.9361 14.6671 13.1095 14.4937C13.2561 14.3471 13.4428 14.1071 13.6028 13.9204C13.7628 13.7337 13.8295 13.5871 13.9361 13.3737C14.0428 13.1471 13.9895 12.9604 13.9095 12.8004C13.8295 12.6404 13.1628 11.0137 12.8961 10.3471C12.6295 9.70707 12.3495 9.78707 12.1495 9.77374H11.5095C11.2828 9.77374 10.9361 9.85374 10.6295 10.1871C10.3361 10.5204 9.48281 11.3204 9.48281 12.9471C9.48281 14.5737 10.6695 16.1471 10.8295 16.3604C10.9895 16.5871 13.1628 19.9204 16.4695 21.3471C17.2561 21.6937 17.8695 21.8937 18.3495 22.0404C19.1361 22.2937 19.8561 22.2537 20.4295 22.1737C21.0695 22.0804 22.3895 21.3737 22.6561 20.6004C22.9361 19.8271 22.9361 19.1737 22.8428 19.0271C22.7495 18.8804 22.5495 18.8137 22.2161 18.6537Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/cardlysa"
                  class="text-white p-2 lg:p-4 text-xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                  >
                    <path
                      d="M18.7241 13.856L28.8501 2H26.4501L17.6601 12.294L10.6361 2H2.53613L13.1561 17.568L2.53613 30H4.93613L14.2201 19.128L21.6381 30H29.7381L18.7241 13.856ZM15.4381 17.704L14.3621 16.154L5.80013 3.82H9.48613L16.3941 13.774L17.4701 15.324L26.4521 28.264H22.7661L15.4381 17.704Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/cardlysa/"
                  class="text-white p-2 lg:p-4 text-xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                  >
                    <path
                      d="M9.38949 6.66702C9.38913 7.37427 9.10784 8.0524 8.60749 8.55225C8.10715 9.0521 7.42873 9.33271 6.72149 9.33236C6.01424 9.332 5.33611 9.05071 4.83626 8.55037C4.33641 8.05002 4.0558 7.3716 4.05615 6.66436C4.05651 5.95711 4.3378 5.27898 4.83814 4.77913C5.33849 4.27928 6.01691 3.99867 6.72415 3.99902C7.4314 3.99938 8.10953 4.28067 8.60938 4.78102C9.10923 5.28136 9.38984 5.95978 9.38949 6.66702ZM9.46949 11.307H4.13615V28.0004H9.46949V11.307ZM17.8962 11.307H12.5895V28.0004H17.8428V19.2404C17.8428 14.3604 24.2028 13.907 24.2028 19.2404V28.0004H29.4695V17.427C29.4695 9.20036 20.0562 9.50702 17.8428 13.547L17.8962 11.307Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </>
        </div>
        <span className="footer-text">
          {formatMessage({
            id: `© ${new Date().getFullYear()} Cardly, All Rights Reserved.`,
          })}
        </span>
      </div>
    </div>
  );
};

export default Footer;

import BritishFlagIcon from "icons/flags/british";
import SaudiaIcon from "icons/flags/saudi.js";
import React, { useState, createContext, useContext } from "react";
const TranslationContext = createContext();

const useTranslation = () => {
  return useContext(TranslationContext);
};

const TranslationProvider = ({ children }) => {
  const [locale, setLocale] = useState("en");

  return (
    <TranslationContext.Provider
      value={{
        locale,
        setLocale,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const AVAILABLE_TRANSLATIONS = [
  {
    name: "Arabic",
    flag: <SaudiaIcon />,
    value: "ar",
  },
  {
    name: "English",
    flag: <BritishFlagIcon />,
    value: "en",
  },
];

export * as translations from "./translation.json";
export { TranslationProvider, useTranslation };

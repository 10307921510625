import React from "react";
import images from "assets/images/Official.png";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "context/auth";

function Header() {
  const { logout } = useAuth();

  return (
    <nav className="tw-relative" data-headlessui-state="">
      <div className="tw-mx-auto md:tw-mb-16 tw-mb-8">
        <div className="tw-relative tw-flex tw-items-center">
          <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div className="tw-flex tw-items-center">
              <a className="" href="/">
                <img className="tw-w-32" src={images} alt="" />
              </a>
            </div>
            <div className="tw-flex tw-items-center tw-justify-end tw-gap-[15px]">
              <div className="tw-hidden sm:tw-block">
                <div className="tw-flex tw-space-x-4">
                  <Link className="" to="/account">
                    <button
                      className="tw-rounded-regular focus:tw-outline-none focus:tw-ring-transparent tw-w-full tw-bg-mint tw-text-white tw-text-medium tw-py-[10px] tw-px-[45px] tw-undefined"
                      type="button"
                    >
                      My account
                    </button>
                  </Link>
                  <div onClick={logout}>
                    <button
                      className="tw-rounded-regular focus:tw-outline-none focus:tw-ring-transparent tw-w-full tw-text-black tw-text-medium tw-p-[10px] tw-undefined"
                      type="button"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-end sm:tw-hidden tw-text-sm">
            <button
              id="headlessui-disclosure-button-:r1:"
              type="button"
              aria-expanded="false"
              data-headlessui-state=""
            >
              <span className="tw-sr-only">Open main menu</span>
              <span className="tw-block tw-border tw-border-[#3a526b] hover:tw-border-brand tw-rounded-md tw-box-shadow tw-px-3 tw-py-1 tw-text-sm tw-text-[#3a526b] hover:tw-text-brand">
                Menu
              </span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;

import React from "react";
import { Outlet } from "react-router-dom";
import Header from "components/header";
import { DesignProvider } from "context/design.js";

function PerosnalAccountLayout() {
  return (
    <DesignProvider>
      <div class="tw-container xl:tw-max-w-screen-xl tw-mx-auto tw-p-7">
        <Header />
        <Outlet />
      </div>
    </DesignProvider>
  );
}

export default PerosnalAccountLayout;

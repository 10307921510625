import { AuthInit } from 'context/auth'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Routes from 'routing/routes'
import { translations, useTranslation } from 'translation'

function App() {
  const { locale } = useTranslation()
  const routes = useRoutes(Routes())

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <AuthInit>{routes}</AuthInit>
      <ToastContainer />
    </IntlProvider>
  )
}

export default App

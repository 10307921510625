import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import PerosnalAccountLayout from 'layouts/personal-account/component/perosnal-account-layout'

// MAIN
const Dashboard = ComponentLoader(
  lazy(() => import('../layouts/personal-account/personal-account'))
)

// ACCOUNT
const Account = ComponentLoader(
  lazy(() => import('../layouts/common/account/account'))
)
const Profile = ComponentLoader(
  lazy(() => import('../layouts/common/account/profile'))
)
const UpdatePassword = ComponentLoader(
  lazy(() => import('../layouts/common/account/update-pass'))
)

// DESIGN
const Design = ComponentLoader(
  lazy(() => import('../layouts/personal-account/customdesign'))
)

const PersonalAccountRoutes = [
  {
    path: '',
    element: <PerosnalAccountLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <Dashboard />,
      },
      {
        path: 'account',
        element: <Account />,
        children: [
          {
            path: '',
            index: true,
            element: <Profile />,
          },
          {
            path: 'password',
            element: <UpdatePassword />,
          },
        ],
      },
      {
        path: 'design',
        element: <Design />,
      },
      {
        path: '*',
        element: <Navigate to={''} />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={''} />,
  },
]

export default PersonalAccountRoutes

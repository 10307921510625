import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'

// PERSONAL
const PersonalAccount = ComponentLoader(
  lazy(() =>
    import('../layouts/authentication/register/personal/personal-account')
  )
)
const SaveWallet = ComponentLoader(
  lazy(() => import('../layouts/authentication/register/personal/save-wallet'))
)

const Steps = [<PersonalAccount />, <SaveWallet />]

const OnboardPersonalAccountRoutes = (step) => [
  {
    path: 'register',
    element: Steps[step - 1],
  },
  {
    path: '*',
    element: <Navigate to={'register'} />,
  },
]

export default OnboardPersonalAccountRoutes

import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from "react";
import * as authHelper from "utils/auth-helper";
import Api from "utils/api";
import Loader from "components/loader";

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  cardholders: 0,
  setCardholders: () => {},
  logout: () => {},
};

const AuthContext = createContext(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState();
  const [cardholders, setCardholders] = useState(0);

  const saveAuth = (auth) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        cardholders,
        setCardholders,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (didRequest.current != auth) {
          setShowSplashScreen(true);
          const { response } = await Api("/auth/verify_token", "get");
          if (response) {
            setCurrentUser(response.data);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      didRequest.current = auth;
    };

    if (auth && auth.token) {
      requestUser(auth.token);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, [auth]);

  return showSplashScreen ? (
    <div className="tw-w-full tw-h-[100vh] tw-flex tw-flex-col tw-items-center tw-justify-center">
      <Loader size={8} />
    </div>
  ) : (
    <>{children}</>
  );
};

export { AuthProvider, AuthInit, useAuth };

import { useAuth } from "context/auth";
import CardlyBlack from "icons/flags/CardlyBlack";
import React from "react";

function RegisterHeader() {
  const { logout } = useAuth();
  return (
    <div className=" tw-mt-[40px] tw-mb-[58px] md:tw-mb-[60px] tw-flex tw-items-center tw-w-full tw-justify-evenly tw-text-[12px]">
      <div></div>
      <CardlyBlack />
      <div>
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  );
}

export default RegisterHeader;

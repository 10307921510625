import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import { ACCOUNT_TYPE } from 'enums/user'

const Register = ComponentLoader(
  lazy(() => import('../layouts/authentication/register/register'))
)

const AccountRegisterationRoutes = [
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: '*',
    element: <Navigate to={'register'} />,
  },
]

export default AccountRegisterationRoutes
